<template>
  <div class="index">
    <Header @do-sign="initUserInfo" />
    <div class="main">
      <div class="base-info">
        <el-upload
          action=""
          class="avatar-uploader"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="userInfo && userInfo.avatar"
            :src="userInfo.avatar"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="user-info">
          <div class="username">
            <span class="width">{{ userInfo && userInfo.userName }}</span>
            <span>已签到{{ userInfo && userInfo.signCount }}天</span>
          </div>
          <div class="grade">
            <span class="width">{{ userInfo && userInfo.userLvName }}</span>
            <el-progress
              v-if="userInfo"
              :percentage="Number(userInfo.userLvRate)"
            ></el-progress>
            <span>{{ userInfo && userInfo.lastLvName }}</span>
          </div>
          <div class="recommend">
            <span class="width">我的推荐码</span>
            <span>{{ userInfo && userInfo.phone }}</span>
          </div>
          <div
            style="
              margin-top: 10px;
              font-size: 14px;
              padding-left: 125px;
              color: #e6a23c;
            "
          >
            <p>1、点击右上角电话头像进入个人中心</p>
            <p>2、上架房源必须下架后才能修改</p>
            <p>3、诚心房标签每日消耗1元梦币</p>
            <p>
              4、签到0-1级会员可获得1元梦币，2级会员可获得2元梦币，3级可获得3元梦币，以此类推。消费金额可以升级”
            </p>
          </div>
        </div>
      </div>
      <div class="user-center">
        <div class="center-title">账户中心</div>
        <div class="content">
          <div class="center-left">
            <ul>
              <li
                :class="currentIndex == index ? 'active' : ''"
                v-for="(item, index) in list"
                v-bind:key="index"
                @click="indexTap(index)"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="center-right" v-if="currentIndex == 0">
            <div class="wallet">
              <img src="../assets/avatar_default.png" alt="" />
              <span>我的钱包</span>
            </div>
            <p class="user-info">
              <span class="user-label">用户：</span>
              <span>{{ accountInfo && accountInfo.phone }}</span>
            </p>
            <p class="user-info">
              <span class="user-label">账号：</span>
              <span>{{ userInfo && userInfo.bankNo }}</span>
            </p>
            <p class="user-info">
              <span class="user-label">现金余额：</span>
              <span>{{ accountInfo && accountInfo.availableAmount }}</span>
              <!-- <span class="supplement">(此金额不能提现)</span> -->
              <button @click="visible1 = true">充值</button>
              <button @click="visible = true">提现</button>
            </p>
            <p class="user-info">
              <span class="user-label">梦币金额：</span>
              <span>{{ accountInfo && accountInfo.signAmount }}</span>
              <span class="supplement">(此金额不能提现)</span>
            </p>
            <div class="user-info">
              <span class="user-label">金额明细</span>
              <span>类型：</span>
              <el-select
                @change="doGetAccountInfo"
                size="small"
                v-model="accountType"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="注册赠送" :value="2"></el-option>
                <el-option label="房源审核通过" :value="3"></el-option>
                <el-option label="充值" :value="4"></el-option>
                <el-option label="查看房东信息" :value="5"></el-option>
                <el-option label="诚心房" :value="6"></el-option>
                <el-option label="签到赠送" :value="1"></el-option>
              </el-select>
              <span style="margin-left: 10px">收入/支出：</span>
              <el-select
                @change="doGetAccountInfo"
                size="small"
                v-model="AccountType"
              >
                <el-option label="收入" :value="1"></el-option>
                <el-option label="支出" :value="2"></el-option>
              </el-select>
              <el-table size="mini" :data="accountData">
                <el-table-column
                  prop="amount"
                  label="金额数值"
                ></el-table-column>
                <el-table-column
                  prop="title"
                  label="金额类型"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="时间"
                ></el-table-column>
              </el-table>
              <p style="text-align: right">
                <el-pagination
                  size="small"
                  layout="prev, pager, next"
                  @current-change="doChangePageSize"
                  :total="accountTotal"
                >
                </el-pagination>
              </p>
            </div>
          </div>
          <div class="center-right" v-if="currentIndex == 1">
            <div class="wallet">
              <img src="../assets/avatar_default.png" alt="" />
              <span>我的发布</span>
            </div>
            <el-tabs
              v-model="activeName"
              v-if="!isRealse"
              @tab-click="doFindReleaseHouse"
            >
              <el-tab-pane label="出售房源" name="1"></el-tab-pane>
              <el-tab-pane label="出租房源" name="2"></el-tab-pane>
            </el-tabs>
            <!-- 出售房源 -->
            <div class="release-content" v-if="activeName === '1' && !isRealse">
              <p class="release-house-btn" @click="doStartRealse(1)">
                <i class="el-icon-plus"></i>发布房源
              </p>
              <div
                class="release-house-item"
                style="position: relative"
                v-for="tmp in releaseList"
                :key="tmp.id"
              >
                <img
                  style="cursor: pointer"
                  @click="goDetail(tmp.houseId, tmp.reviewStatus, 1)"
                  :src="tmp.coverImg"
                  alt=""
                />
                <div class="base-info" style="position: relative">
                  <p
                    class="house-name"
                    @click="goDetail(tmp.houseId, tmp.reviewStatus, 1)"
                  >
                    {{ tmp.title }}
                  </p>
                  <p>
                    <i class="icon el-icon-location-outline"></i
                    ><span>{{ tmp.community }}</span>
                  </p>
                  <p>
                    <i class="icon el-icon-office-building"></i
                    ><span
                      >{{ tmp.stayFloor }}楼/共{{ tmp.totalFloor }}层丨{{
                        tmp.unitType
                      }}丨{{ tmp.rentArea }}m²</span
                    >
                  </p>
                  <p>
                    <svg-icon class="icon" icon-class="xihuan-a"></svg-icon
                    ><span
                      >{{ tmp.followCount }}人关注丨{{ tmp.createTime }}</span
                    >
                  </p>
                  <span
                    style="
                      position: absolute;
                      right: 0;
                      top: 0;
                      color: rgb(0, 194, 151);
                    "
                    >{{ tmp.isRecommend ? "诚心房" : "" }}</span
                  >
                </div>
                <div class="price-info">
                  <div class="price">
                    <p class="red">{{ tmp.sellPrice / 10000 }}万</p>
                    <p>单价{{ tmp.unitPrice }}元 / 平米</p>
                  </div>
                </div>
                <div style="position: absolute; right: 0; top: 0">
                  <el-button
                    v-if="tmp.reviewStatus !== 1 && tmp.rentStatus !== 1"
                    type="text"
                    @click="doEditHouse(tmp, '1')"
                    >修改</el-button
                  >
                  <el-button type="text" @click="doOutHouse(tmp)"
                    >下架</el-button
                  >
                  <el-button
                    type="text"
                    style="color: red"
                    @click="doDeleteHouse(tmp)"
                    >删除</el-button
                  >
                  <el-button type="text" @click="goodSale(tmp)"
                    >是否诚心房</el-button
                  >
                </div>
                <div class="rent-status" v-if="tmp.reviewStatus === 1">
                  审核中
                </div>
                <div
                  v-else
                  class="rent-status"
                  :class="{ 'rent-1': tmp.rentStatus === 1 }"
                >
                  {{
                    tmp.rentStatus === 1
                      ? "已上架"
                      : tmp.rentStatus === 2
                      ? "已下架"
                      : tmp.rentStatus === 3
                      ? "已删除"
                      : tmp.rentStatus === 4
                      ? "已出售"
                      : ""
                  }}
                </div>
              </div>
            </div>
            <!-- 发布出售房源 -->
            <div
              class="new-release-wrapper"
              v-if="isRealse && realseForm.houseType === 1"
            >
              <p style="margin-top: 10px">
                <i
                  class="el-icon-arrow-left go-back"
                  @click="isRealse = false"
                />
                <span>{{ isEdit ? "修改" : "发布" }}出售房源</span>
              </p>
              <!-- 出售发布 -->
              <ReleaseHouse
                @edit="doConfirmEditHouse"
                @cancel="doCancelHouse"
                ref="releaseHouse"
                :form="realseForm"
                @success="doSuccess(1)"
              />
            </div>
            <!-- 出租房源 -->
            <div class="rent-content" v-if="activeName === '2' && !isRealse">
              <p class="rent-house-btn" @click="doStartRealse(2)">
                <i class="el-icon-plus"></i>出租房源
              </p>
              <div
                class="rent-house-item"
                style="position: relative"
                v-for="tmp in releaseList"
                :key="tmp.id"
              >
                <img
                  style="cursor: pointer"
                  @click="goDetail(tmp.houseId, tmp.reviewStatus, 2)"
                  :src="tmp.coverImg"
                  alt=""
                />
                <div class="base-info" style="position: relative">
                  <p
                    class="house-name"
                    @click="goDetail(tmp.houseId, tmp.reviewStatus, 2)"
                  >
                    <span class="rent-type">{{ tmp.rentType }}</span>
                    <span>{{ tmp.title }}</span>
                  </p>
                  <p>
                    <i class="icon el-icon-location-outline"></i
                    ><span>{{ tmp.community }}</span>
                  </p>
                  <p>
                    <svg-icon class="icon" icon-class="like"></svg-icon
                    ><span
                      >{{ tmp.followCount }}人关注丨{{ tmp.createTime }}</span
                    >
                  </p>
                  <span
                    style="
                      position: absolute;
                      right: 0;
                      top: 0;
                      color: rgb(0, 194, 151);
                    "
                    >{{ tmp.isRecommend ? "诚心房" : "" }}</span
                  >
                </div>
                <div class="price-info">
                  <div class="price">
                    <p class="red">{{ tmp.sellPrice }}元/月</p>
                  </div>
                </div>
                <div style="position: absolute; right: 0; top: 0">
                  <el-button
                    v-if="tmp.reviewStatus !== 1 && tmp.rentStatus !== 1"
                    type="text"
                    @click="doEditHouse(tmp, '2')"
                    >修改</el-button
                  >
                  <el-button type="text" @click="doOutHouse(tmp)"
                    >下架</el-button
                  >
                  <el-button
                    type="text"
                    style="color: red"
                    @click="doDeleteHouse(tmp)"
                    >删除</el-button
                  >
                  <el-button type="text" @click="goodSale(tmp)"
                    >是否诚心房</el-button
                  >
                </div>
                <div class="rent-status" v-if="tmp.reviewStatus === 1">
                  审核中
                </div>
                <div
                  v-else
                  class="rent-status"
                  :class="{ 'rent-1': tmp.rentStatus === 1 }"
                >
                  {{
                    tmp.rentStatus === 1
                      ? "已上架"
                      : tmp.rentStatus === 2
                      ? "已下架"
                      : tmp.rentStatus === 3
                      ? "已删除"
                      : tmp.rentStatus === 4
                      ? "已交易"
                      : ""
                  }}
                </div>
              </div>
            </div>
            <!-- 发布出租房源 -->
            <div
              class="new-release-wrapper"
              v-if="isRealse && realseForm.houseType === 2"
            >
              <p style="margin-top: 10px">
                <i
                  class="el-icon-arrow-left go-back"
                  @click="isRealse = false"
                />
                <span>{{ isEdit ? "修改" : "发布" }}出租房源</span>
              </p>
              <!-- 出租发布 -->
              <ReleaseHouse
                @edit="doConfirmEditHouse"
                @cancel="doCancelHouse"
                ref="releaseHouse"
                :form="realseForm"
                @success="doSuccess(2)"
              />
            </div>
          </div>
          <div class="center-right" v-if="currentIndex == 2">
            <div class="wallet">
              <img src="../assets/avatar_default.png" alt="" />
              <span>我的关注</span>
            </div>
            <el-tabs v-model="activeName1" @tab-click="doFindLikeHouse">
              <el-tab-pane label="二手房" name="1"></el-tab-pane>
              <el-tab-pane label="租房" name="2"></el-tab-pane>
            </el-tabs>
            <div class="release-content" v-if="activeName1 === '1'">
              <div
                class="release-house-item"
                v-for="tmp in followList"
                :key="tmp.id"
              >
                <img
                  style="cursor: pointer"
                  @click="goDetail(tmp.houseId, tmp.reviewStatus, 1)"
                  :src="tmp.coverImg"
                  alt=""
                />
                <div class="base-info">
                  <p
                    class="house-name"
                    @click="goDetail(tmp.houseId, tmp.reviewStatus, 1)"
                  >
                    {{ tmp.title }}
                  </p>
                  <p>
                    <i class="icon el-icon-location-outline"></i
                    ><span>{{ tmp.community }}</span>
                  </p>
                  <p>
                    <i class="icon el-icon-office-building"></i
                    ><span
                      >{{ tmp.stayFloor }}楼/共{{ tmp.totalFloor }}层丨{{
                        tmp.unitType
                      }}丨{{ tmp.rentArea }}m²</span
                    >
                  </p>
                  <p>
                    <svg-icon class="icon" icon-class="like"></svg-icon
                    ><span
                      >{{ tmp.followCount }}人关注丨{{ tmp.createTime }}</span
                    >
                  </p>
                </div>
                <div class="price-info">
                  <span class="like-info" @click="doFollowOrNot(tmp.houseId)">
                    <svg-icon
                      class="icon"
                      :icon-class="tmp.isFollow ? 'xihuan-a' : 'like'"
                    />
                    <span>{{ tmp.isFollow ? "取消关注" : "关注" }}</span>
                  </span>
                  <div class="price">
                    <p class="red">{{ tmp.sellPrice / 10000 }}万</p>
                    <p>单价{{ tmp.unitPrice }}元 / 平米</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="rent-content" v-else>
              <div
                class="rent-house-item"
                v-for="tmp in followList"
                :key="tmp.id"
              >
                <img
                  style="cursor: pointer"
                  @click="goDetail(tmp.houseId, tmp.reviewStatus, 2)"
                  :src="tmp.coverImg"
                  alt=""
                />
                <div class="base-info">
                  <p
                    class="house-name"
                    @click="goDetail(tmp.houseId, tmp.reviewStatus, 2)"
                  >
                    <span class="rent-type">{{ tmp.rentType }}</span>
                    <span>{{ tmp.title }}</span>
                  </p>
                  <p>
                    <i class="icon el-icon-location-outline"></i
                    ><span>{{ tmp.community }}</span>
                  </p>
                  <p>
                    <svg-icon class="icon" icon-class="like"></svg-icon
                    ><span
                      >{{ tmp.followCount }}人关注丨{{ tmp.createTime }}</span
                    >
                  </p>
                </div>
                <div class="price-info">
                  <span class="like-info" @click="doFollowOrNot(tmp.houseId)">
                    <svg-icon
                      class="icon"
                      :icon-class="tmp.isFollow ? 'xihuan-a' : 'like'"
                    />
                    <span>{{ tmp.isFollow ? "取消关注" : "关注" }}</span>
                  </span>
                  <div class="price">
                    <p class="red">{{ tmp.sellPrice }}元/月</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="center-right" v-if="currentIndex == 3">
            <div class="wallet">
              <img src="../assets/avatar_default.png" alt="" />
              <span>我的支付宝/微信</span>
            </div>
            <div class="bank-list-wrapper">
              <el-table size="small" :data="bankList">
                <el-table-column
                  label="支付宝/微信"
                  prop="bankName"
                ></el-table-column>
                <el-table-column
                  label="支付宝/微信账号"
                  prop="bankNo"
                ></el-table-column>
                <el-table-column label="姓名" prop="cardName"></el-table-column>
                <el-table-column label="操作" prop="action">
                  <template scope="scope">
                    <el-button
                      @click="doDeleteBank(scope.row)"
                      style="color: #f56c6c"
                      size="small"
                      type="text"
                      >删除</el-button
                    >
                    <el-button
                      @click="doEditBank(scope.row)"
                      size="small"
                      type="text"
                      >修改</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                @click="doBankCard"
                type="primary"
                size="small"
                style="width: 100%; margin: 20px 0"
                >添加支付宝/微信</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <BankCard ref="bankcard" @ok="doFindBankList" />
    <!-- 提现 -->
    <el-dialog
      width="30%"
      title="提现到支付宝/微信"
      :visible="visible"
      @close="doCancelTixian"
    >
      <el-form label-width="120px" :model="withdrawal">
        <el-form-item label="支付宝/微信：">
          <el-select
            v-model="withdrawal.bankId"
            size="small"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="tmp in bankList"
              :key="tmp.bankId"
              :label="`${tmp.bankName} ${tmp.bankNo}`"
              :value="tmp.bankId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提现金额：">
          <el-input
            type="number"
            v-model="withdrawal.amount"
            size="small"
            placeholder="请输入"
          ></el-input>
          <span class="get-uuid" style="right: 10px" @click="doGetUuid">{{
            uuidTxt
          }}</span>
        </el-form-item>
        <el-form-item label="验证码：">
          <el-input
            v-model="withdrawal.code"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="doCancelTixian">取消</el-button>
        <el-button size="small" type="primary" @click="doConfirmTixian"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <!-- 充值 -->
    <el-dialog
      width="30%"
      title="充值"
      :visible="visible1"
      @close="doCancelTopUp"
    >
      <el-form>
        <el-form-item label="充值金额：">
          <el-input
            type="number"
            v-model="topUp.amount"
            size="small"
            style="width: 300px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="充值类型：">
          <el-radio-group v-model="topUp.rechargeType" @change="qrUrl = ''">
            <el-radio :label="1">支付宝</el-radio>
            <el-radio :label="2">微信</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="qr-wrapper" v-if="qrUrl">
        <VueQr
          :size="200"
          :margin="10"
          :auto-color="true"
          :dot-scale="1"
          :text="qrUrl"
        />
        <p
          style="
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #00c297;
          "
        >
          {{ payResult }}
        </p>
      </div>
      <div slot="footer">
        <el-button size="small" @click="doCancelTopUp">取消</el-button>
        <el-button size="small" type="primary" @click="doConfirmTopUp"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="是否诚心房" :visible.sync="dialogVisible" width="30%">
      <el-radio-group v-model="isRecommend">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
      <p style="text-align: right">
        <el-button type="default" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="doConfirmCommend">确定</el-button>
      </p>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import BankCard from "@/components/bank-card.vue";
import ReleaseHouse from "@/components/release-house.vue";
import VueQr from "vue-qr";
export default {
  name: "Account",
  components: { Header, BankCard, ReleaseHouse, VueQr },
  data() {
    return {
      AccountType: 1,
      accountType: "",
      commendItem: null,
      dialogVisible: false,
      isRecommend: false,
      payResult: "支付中...",
      payTimer: null,
      qrUrl: "",
      visible: false,
      visible1: false,
      activeName: "1",
      activeName1: "1",
      list: ["我的钱包", "我的发布", "我的关注", "我的支付宝/微信"],
      currentIndex: 0,
      userInfo: null,
      accountInfo: null,
      bankList: [],
      uuidTxt: "获取验证码",
      withdrawal: {
        amount: null,
        bankId: "",
        code: "",
        uuid: "",
      },
      withTimer: null,
      topUp: {
        amount: null,
        rechargeType: 1,
      },
      // 我的发布
      releaseList: [],
      // 我的喜欢
      followList: [],
      // 发布
      isRealse: false,
      isEdit: false,
      realseForm: {
        houseType: 1,
      },
      accountData: [],
      accountTotal: 0,
      pageNum: 1,
    };
  },
  mounted() {
    this.initUserInfo();
    this.initAccountInfo();
    this.doFindBankList();
    this.doGetAccountInfo();
  },
  methods: {
    async doConfirmCommend() {
      const res = await this.$request.post("/api/house/updateHonesty", {
        houseId: this.commendItem.houseId,
      });
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.doFindReleaseHouse();
      } else {
        this.$message.warning("操作有误！");
      }
      this.dialogVisible = false;
    },
    async goodSale(tmp) {
      this.commendItem = tmp;
      this.isRecommend = tmp.isRecommend;
      this.dialogVisible = true;
    },
    doChangePageSize(pageNum) {
      this.pageNum = pageNum;
      this.doGetAccountInfo();
    },
    async doGetAccountInfo() {
      let url =
        this.AccountType === 1
          ? "/api/account/queryAccountList"
          : "/api/account/queryAccountExpList";
      const res = await this.$request.post(
        `${url}?pageSize=10&pageNum=${this.pageNum}&type=${this.accountType}`
      );
      if (res.code === 200) {
        this.accountData = res.data.rows;
        if (this.AccountType === 2) {
          this.accountData = this.accountData.map((n) => {
            n.amount = "-" + n.amount;
            return n;
          });
        }
        this.accountTotal = res.data.total;
      }
    },
    async doConfirmEditHouse(data) {
      const res = await this.$request.post("/api/house/updateHouseInfo", data);
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.doFindReleaseHouse();
        this.doCancelHouse();
      } else {
        this.$message.warning(res.msg);
      }
    },
    doCancelHouse() {
      this.isRealse = false;
      this.isEdit = false;
    },
    doDeleteHouse(tmp) {
      this.$confirm("此操作将删除该房屋, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$request
          .post("/api/house/updateHouseStatus", {
            houseId: tmp.houseId,
            rentStatus: 3,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.doFindReleaseHouse();
            } else {
              this.$message.warning(res.msg);
            }
          });
      });
    },
    doOutHouse(tmp) {
      this.$confirm("此操作将下架该房屋, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$request
          .post("/api/house/updateHouseStatus", {
            houseId: tmp.houseId,
            rentStatus: 2,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.doFindReleaseHouse();
            } else {
              this.$message.warning(res.msg);
            }
          });
      });
    },
    doEditHouse(tmp) {
      // type '1' 发布  '2' 出租
      this.doGetHouseDetail(tmp.houseId);
      this.isRealse = true;
      this.isEdit = true;
    },
    async doGetHouseDetail(houseId) {
      const res = await this.$request.get("/api/house/getOwnHouseInfo", {
        houseId,
      });

      if (res.code === 200) {
        let house = res.data.house;
        let info = res.data.info;
        this.$refs["releaseHouse"].realseForm = Object.assign(
          {},
          {
            nickName: house.nickName.slice(0, house.nickName.length - 2),
            area: house.area,
            buildingDate: house.buildingDate,
            checkInDate: house.checkInDate,
            city: house.city,
            community: house.community,
            coverImg: house.coverImg,
            descs: house.descs,
            houseId: house.houseId,
            houseType: house.houseType,
            imgList: res.data.imgList,
            sfzPositiveImg: info.sfzPositiveImg,
            sfzBlackImg: info.sfzBlackImg,
            deedImg: info.deedImg,
            addressDescs: house.addressDescs,
            // recomPhone: data.recomPhone,
            info: {
              architectureType: info.architectureType,
              decoration: info.decoration,
              elevatorFlag: info.elevatorFlag,
              proportion: info.proportion,
              stayFloor: info.stayFloor,
              totalArea: info.totalArea,
              totalFloor: info.totalFloor,
              towards: info.towards,
              unitType: info.unitType,
            },
            payType: house.payType,
            phone: house.phone,
            province: house.province,
            provinceCode: house.provinceCode,
            rentArea: house.rentArea,
            rentType: house.rentType,
            sellPrice:
              house.houseType === 1 ? house.sellPrice / 10000 : house.sellPrice,
            title: house.title,
          }
        );
        this.$refs["releaseHouse"].ti = info.proportion.split("梯")[0];
        let hu = info.proportion.split("梯")[1];
        this.$refs["releaseHouse"].hu = hu ? hu.split("户")[0] : "";
        this.$refs["releaseHouse"].provinceCode = house.provinceCode.split(",");
        this.$refs["releaseHouse"].isEdit = true;
        this.$refs["releaseHouse"].nickNameSex = house.nickName.slice(
          house.nickName.length - 2,
          house.nickName.length
        );
        this.$refs["releaseHouse"].sellPrice =
          house.houseType === 1 ? house.sellPrice / 10000 : house.sellPrice;
      }
    },
    goDetail(houseId, status, type) {
      if (status === 1) {
        this.$message.warning("该房屋正在审核中，暂不可查看！");
        return;
      }
      this.$router.push({ path: "house-detail", query: { houseId, type } });
    },
    doSuccess(type) {
      this.isRealse = false;
      this.realseForm.houseType = type;
      this.doFindReleaseHouse();
    },
    doFollowOrNot(houseId) {
      this.$request
        .post("/api/house/updateCanOrAtt", { houseId })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.doFindLikeHouse();
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    doFindReleaseHouse(tab) {
      this.releaseList = [];
      if (tab && tab.name) {
        this.realseForm.houseType = Number(tab.name);
      }
      this.$request
        .get("/api/house/queryReleaseHouseList", {
          houseType: this.realseForm.houseType,
        })
        .then((res) => {
          if (res.code === 200) {
            this.releaseList = res.data.rows;
          } else {
            this.releaseList = [];
          }
        });
    },
    doFindLikeHouse() {
      this.$request
        .get("/api/house/queryFollowHouseList", {
          houseType: Number(this.activeName1),
        })
        .then((res) => {
          if (res.code === 200) {
            this.followList = res.data.rows;
          } else {
            this.followList = [];
          }
        });
    },
    doStartRealse(type) {
      this.realseForm.houseType = type;
      this.isRealse = true;
    },
    doConfirmTixian() {
      this.$request
        .post("/api/withdraw/setWithdraw", this.withdrawal)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.doCancelTixian();
            this.initAccountInfo();
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    doGetPayInfo(orderNo) {
      this.$request
        .get("/api/withdraw/getPayResult", { orderNo })
        .then((res) => {
          if (res.code === 200) {
            const result = res.data.payStatus;
            this.payResult =
              result === "TRADE_SUCCESS" ? "支付成功" : "支付中...";
            if (
              result === "TRADE_CLOSED" ||
              result === "TRADE_FINISHED" ||
              result === "TRADE_SUCCESS"
            ) {
              clearInterval(this.payTimer);
              this.payTimer = null;
              setTimeout(() => {
                this.doCancelTopUp();
                this.initAccountInfo();
                this.payResult = "";
              }, 2000);
            }
          }
        });
    },
    doConfirmTopUp() {
      this.$request.post("/api/withdraw/recharge", this.topUp).then((res) => {
        if (res.code === 200) {
          this.qrUrl = res.data.res;
          const orderNo = res.data.orderNo;
          this.payTimer = setInterval(() => {
            this.doGetPayInfo(orderNo);
          }, 2000);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    doCancelTopUp() {
      this.topUp = {
        amount: null,
        rechargeType: 1,
      };
      this.qrUrl = "";
      this.visible1 = false;
    },
    doCancelTixian() {
      this.withdrawal = {
        amount: null,
        bankId: "",
        code: "",
        uuid: "",
      };
      this.visible = false;
    },
    doGetUuid() {
      this.$request.get("/api/sms/sedWithdraw").then((res) => {
        if (res.code === 200) {
          this.withdrawal.uuid = res.data.uuid;
          let i = 60;
          this.withTimer = setInterval(() => {
            if (i === 0) {
              clearInterval(this.withTimer);
              this.withTimer = null;
              this.uuidTxt = "获取验证码";
              return;
            }
            this.uuidTxt = i + " s";
            i--;
          }, 1000);
          this.$message.success(res.msg);
        } else {
          this.withdrawal.uuid = "";
        }
      });
    },
    doDeleteBank(row) {
      this.$confirm("此操作将删除该银行卡，确定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$request
          .post("/api/bank/delete", {
            bankId: row.bankId,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.doFindBankList();
            } else {
              this.$message.warning(res.msg);
            }
          });
      });
    },
    doEditBank(row) {
      this.$request.get(`/api/bank/info/${row.bankId}`).then((res) => {
        if (res.code === 200) {
          const bankcard = this.$refs.bankcard;
          Object.keys(bankcard.form).forEach(
            (key) => (bankcard.form[key] = res.data[key])
          );
          bankcard.form.cardName = res.data.cardName;
          // bankcard.form.bankChooseId = res.data.bankName
          bankcard.type = 2;
          bankcard.showDialog();
        }
      });
    },
    doConfirmDefault(row) {
      this.$confirm("此操作将设置该银行卡为默认银行卡，确定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$request
          .post("/api/bank/setDefault", {
            bankId: row.bankId,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.doFindBankList();
            } else {
              this.$message.warning(res.msg);
            }
          });
      });
    },
    doCancelDefault(row) {
      this.$confirm("此操作将取消该银行卡为默认银行卡，确定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$request
          .post("/api/bank/cancelDefault", {
            bankId: row.bankId,
          })
          .then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.doFindBankList();
            } else {
              this.$message.warning(res.msg);
            }
          });
      });
    },
    doBankCard() {
      this.$refs.bankcard.type = 1;
      this.$refs.bankcard.showDialog();
    },
    beforeAvatarUpload(file) {
      let formData = new FormData();
      formData.append("avatarfile", file);
      this.$request.post("/api/user/updateAvatar", formData).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.initUserInfo();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    initUserInfo() {
      this.$request.get("/api/user/getUserInfo").then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
        }
      });
    },
    initAccountInfo() {
      this.$request.get("/api/user/getUserAccount").then((res) => {
        if (res.code === 200) {
          this.accountInfo = res.data;
        }
      });
    },
    indexTap(index) {
      this.currentIndex = index;
      if (index === 3) {
        this.doFindBankList();
      }
      if (index === 1) {
        this.doFindReleaseHouse();
      }
      if (index === 2) {
        this.doFindLikeHouse();
      }
    },
    // 银行卡列表
    doFindBankList() {
      this.$request.get("/api/bank/list").then((res) => {
        if (res.code === 200) {
          this.bankList = res.data;
        } else {
          this.bankList = [];
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variable.scss";
.index {
  width: 1200px;
  margin: 0 auto;
}
.main {
  padding: 30px 100px;
  .base-info {
    margin: 0 auto;
    display: flex;
    .user-info {
      margin: auto 0;
      line-height: 26px;
      font-size: 16px;
      color: #333;
      .username {
        display: inline-block;
      }
      .grade {
        display: flex;
        justify-content: center;
        align-items: center;
        .progress {
          margin: 0 20px;
        }
      }
      .recommend {
        display: inline-block;
        span {
          font-size: 14px;
        }
      }
      .width {
        text-align: right;
        display: inline-block;
        width: 100px;
        margin-right: 30px;
      }
    }
  }
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-center {
    .center-title {
      margin-top: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #666;
      margin-bottom: 10px;
    }
    .content {
      display: flex;
      .center-left {
        padding-top: 15px;
        width: 165px;
        border-top: 1px solid $mainColor;
        li {
          cursor: pointer;
          border-bottom: 1px solid $defaultColor;
          text-align: center;
          line-height: 40px;
        }
        .active {
          background: $mainColor;
          color: #fff;
        }
      }
      .center-right {
        flex: 1;
        border-top: 1px solid #d7d7d7;
        padding: 15px 0 0 30px;
        .user-info {
          margin: 15px 0;
          .user-label {
            display: inline-block;
            width: 80px;
            color: $textColor;
          }
          button {
            cursor: pointer;
            padding: 2px 10px;
            background-color: $mainColor;
            border: none;
            outline: none;
            color: #fff;
            border-radius: 15px;
            margin: 0 10px;
          }
        }
        .wallet {
          display: flex;
          background: #f2f2f2;
          padding: 10px 40px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 20px;
          }
          span {
            font-size: 16px;
            color: #999;
          }
        }
        .release-content,
        .rent-content {
          .release-house-btn,
          .rent-house-btn {
            color: $textColor;
            cursor: pointer;
            user-select: none;
            i {
              font-size: 20px;
              font-weight: bold;
              margin-right: 10px;
            }
            &:active {
              color: $mainColor;
            }
          }
        }
        .release-house-item,
        .rent-house-item {
          margin-top: 20px;
          border-bottom: 1px solid $defaultColor;
          padding-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: textColor;
          .base-info {
            width: 40%;
            height: 180px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            .icon {
              margin-right: 10px;
            }
            .house-name {
              font-size: 24px;
              font-weight: bold;
              cursor: pointer;
              color: $mainColor;
            }
          }
          .price-info {
            position: relative;
            text-align: right;
            height: 165px;
            .price {
              margin-top: 80px;
              .red {
                font-size: 24px;
                color: $redColor;
                font-weight: bold;
                margin-bottom: 40px;
              }
            }
          }
          img {
            width: 250px;
            height: 200px;
          }
          .house-tip {
            span {
              padding: 5px 10px;
              color: #fff;
              margin-right: 10px;
              background-color: $mainColor;
            }
          }
        }
        .rent-type {
          color: $secondColor;
          margin-right: 20px;
        }
        .like-info {
          position: absolute;
          top: 0;
          right: 0;
          color: $textColor;
          cursor: pointer;
          .icon {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .new-release-wrapper {
    .go-back {
      font-size: 22px;
      margin-right: 10px;
      cursor: pointer;
      vertical-align: middle;
    }
    .el-input__inner {
      width: 100%;
      border-radius: 0;
    }
    .el-cascader {
      width: 100%;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
  }
  .release-btn {
    text-align: center;
    button {
      width: 35%;
    }
  }
}
.change-avatar {
  text-align: center;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}
.rent-status {
  position: absolute;
  right: 180px;
  top: 50px;
  padding: 2px 5px;
  font-size: 14px;
  font-weight: bold;
  color: #e6a23c;
  border: 1px solid #e6a23c;
  border-radius: 15px;
  transform: rotate(-15deg);
}
.rent-sell {
  position: absolute;
  right: 110px;
  top: 50px;
}
.rent-1 {
  color: #52ae58;
  border: 1px solid #52ae58;
}
.el-progress {
  width: 155px;
}
.el-progress-bar__inner {
  background: $mainColor;
}
.el-tabs__item {
  color: $textColor;
  font-size: 18px;
  font-weight: bold;
}
.el-tabs__item:hover {
  color: $mainColor;
}
.el-tabs__item.is-active {
  color: $mainColor;
}
.el-tabs__active-bar {
  background-color: $mainColor;
}
.el-button--primary,
.el-button--primary:focus,
.el-button--primary:hover {
  background-color: $mainColor;
  border-color: $mainColor;
}
.bank-list-wrapper {
  text-align: center;
}
.el-table--small td,
.el-table--small th {
  padding: 4px 0;
}
.el-upload--picture-card {
  width: 50px;
  height: 50px;
  line-height: 60px;
}
.coverImg-wrapper {
  width: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.el-radio__input.is-checked + .el-radio__label {
  color: $mainColor;
}
.el-radio__input.is-checked .el-radio__inner {
  background-color: $mainColor;
  border-color: $mainColor;
}
.qr-wrapper {
  width: 100%;
  text-align: center;
}
</style>
