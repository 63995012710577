<template>
  <el-dialog
      :title="`${type === 1 ? '添加' : '修改'}支付宝/微信`"
      width='30%'
      :visible='visible'
      @close='doCancel'
  >
    <el-form ref="form" label-width="150px" :model="form" :rules="rules">
      <el-form-item label="支付宝/微信：" prop="bankName">
        <el-select v-model="form.bankName" size="small" style="width: 100%">
          <el-option label="支付宝" value="支付宝"></el-option>
          <el-option label="微信" value="微信"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付宝/微信账号：" prop="bankNo">
        <el-input size="small" placeholder="请输入" v-model="form.bankNo"></el-input>
      </el-form-item>
      <el-form-item label="姓名：" prop="cardName">
        <el-input size="small" placeholder="请输入" v-model="form.cardName"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button size="small" type="default" @click="doCancel">取消</el-button>
      <el-button size="small" type="primary" @click="doConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'bank-card',
  data () {
    return {
      type: 1, // 1新增 2修改
      visible: false,
      rules: {
        bankName: [{ required: true, message: '必填！' }],
        bankNo: [{ required: true, message: '必填！' }],
        cardName: [{ required: true, message: '必填！' }]
      },
      form: {
        bankId: '',
        bankName: '',
        bankNo: '',
        cardName: ''
      },
      bankList: [],
      uuidTxt: '获取验证码',
      bankTimer: null
    }
  },
  methods: {
    doCancel () {
      this.form = {
        bankNo: '',
        bankId: '',
        bankChooseId: '',
        isDefault: 1,
        phone: '',
        uuid: '',
        vcode: '',
        bankLogoUrl: '',
        bankName: ''
      }
      this.visible = false
      this.$refs.form.resetFields()
    },
    showDialog () {
      this.visible = true
    },
    doChangeBank (val) {
      const bank = this.bankList.find(n => n.bankId === val)
      if (bank) {
        this.form.bankName = bank.bankName
        this.form.bankLogoUrl = bank.logoUrl
      }
    },
    doConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url = this.type === 1 ? '/api/bank/add' : '/api/bank/edit'
          this.$request.post(url, this.form).then(res => {
            if (res.code === 200) {
              this.$message.success('操作成功！')
              this.doCancel()
              this.$emit('ok')
            } else {
              this.$message.warning('操作失败！')
            }
          })
        }
      })
    
    },
    doGetUuid () {
      if (this.form.phone.length < 11) {
        this.$message.warning('请输入手机号码!')
      } else {
        this.$request.get(`/api/sms/sedBank/${this.form.phone}`).then(res => {
          if (res.code === 200) {
            this.form.uuid = res.data.uuid
          }
        })
        let i = 60
        this.bankTimer = setInterval(() => {
          if (i === 0) {
            clearInterval(this.bankTimer)
            this.bankTimer = null
            this.uuidTet = '获取验证码'
            return
          }
          this.uuidTxt = i + ' s'
          i--
        }, 1000)
      }
    }
  }
}
</script>
<style lang='scss'>
@import "../styles/variable.scss";
.el-dialog__body  {
  padding: 10px 20px;
}
.el-form-item {
  margin-bottom: 15px;
}
.get-uuid {
  display: block;
  text-align: right;
  top: 0;
  position: absolute;
  right: 10px;
  color: $mainColor;
  cursor: pointer;
}
</style>
