<template>
  <el-form
    ref="realseForm"
    :model="realseForm"
    :rules="rules"
    label-width="120px"
  >
    <el-row>
      <el-col :span="12">
        <el-form-item label="标题：" prop="title">
          <el-input
            v-model="realseForm.title"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="省市区域：" prop="area">
          <el-cascader
            size="small"
            v-model="provinceCode"
            :props="{ label: 'name', value: 'code' }"
            @change="doChangeDate"
            :options="areaInfo"
          >
          </el-cascader>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所在小区：" prop="community">
          <el-input
            v-model="realseForm.community"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所在楼层：" prop="info.stayFloor">
          <el-input
            v-model="realseForm.info.stayFloor"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="详细地址：" prop="addressDescs">
          <el-input
            v-model="realseForm.addressDescs"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="梯户比例：" prop="info.proportion">
          <el-row>
            <el-col :span="8">
              <el-input
                v-model="ti"
                @input="doChangeTihu($event, 'ti')"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-col>
            <el-col :span="4">
              <span style="margin-left: 15px; color: #606266">梯</span>
            </el-col>
            <el-col :span="8">
              <el-input
                v-model="hu"
                @input="doChangeTihu($event, 'hu')"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-col>
            <el-col :span="4">
              <span style="margin-left: 15px; color: #606266">户</span>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="总楼层：" prop="info.totalFloor">
          <el-input
            v-model="realseForm.info.totalFloor"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="朝向：" prop="info.towards">
          <el-input
            v-model="realseForm.info.towards"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="户型：" prop="info.unitType">
          <el-input
            v-model="realseForm.info.unitType"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="有无电梯：" prop="info.elevatorFlag">
          <el-radio-group v-model="realseForm.info.elevatorFlag">
            <el-radio :label="0">无</el-radio>
            <el-radio :label="1">有</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="建筑时间：" prop="buildingDate">
          <el-date-picker
            size="small"
            format="yyyy"
            value-format="yyyy"
            v-model="realseForm.buildingDate"
            type="year"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="看房时间：" prop="checkInDate">
          <el-input
            size="small"
            placeholder="请输入 如：可随时看房"
            v-model="realseForm.checkInDate"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="房东姓名：" prop="nickName">
          <el-input
            style="width: 150px"
            v-model="realseForm.nickName"
            size="small"
            placeholder="请输入"
          ></el-input>
          <el-select
            v-model="nickNameSex"
            style="width: 100px; margin-left: 30px"
            size="small"
          >
            <el-option value="先生">先生</el-option>
            <el-option value="女士">女士</el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="手机号码：" prop="phone">
          <el-input
            v-model="realseForm.phone"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="装修情况：" prop="info.decoration">
          <el-input
            v-model="realseForm.info.decoration"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="房屋面积：" prop="rentArea">
          <el-input
            v-model="realseForm.rentArea"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="房屋价格：" prop="sellPrice">
          <el-input
            style="width: 245px"
            v-model="realseForm.sellPrice"
            size="small"
            placeholder="请输入"
          ></el-input>
          <span style="margin-left: 5px">{{
            realseForm.houseType === 1 ? "万元" : "元"
          }}</span>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="12">
          <el-form-item label="推荐人号码：">
              <el-input :disabled='isEdit' v-model="realseForm.pidPhone" size='small' placeholder='请输入'></el-input>
          </el-form-item>
      </el-col> -->
    </el-row>
    <el-row>
      <el-col :span="12" v-if="realseForm.houseType === 2">
        <el-form-item label="付款方式：" prop="payType">
          <el-radio-group v-model="realseForm.payType">
            <el-radio label="月付">月付</el-radio>
            <el-radio label="季付">季付</el-radio>
            <el-radio label="年付">年付</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="realseForm.houseType === 2">
        <el-form-item label="出租方式：">
          <el-radio-group v-model="realseForm.rentType">
            <el-radio label="整租">整租</el-radio>
            <el-radio label="合租">合租</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="封面图片：" prop="coverImg">
          <el-row>
            <el-col :span="6">
              <div style="position: relative" v-if="realseForm.coverImg">
                <img
                  style="
                    width: 50px;
                    height: 50px;
                    vertical-align: middle;
                    margin-right: 10px;
                  "
                  :src="realseForm.coverImg"
                  alt=""
                />
                <i
                  @click="realseForm.coverImg = ''"
                  style="
                    margin-top: 10px;
                    cursor: pointer;
                    color: red;
                    position: absolute;
                    top: -20px;
                    right: 15px;
                  "
                  class="el-icon-close"
                ></i>
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                style="display: inline-block"
                action=""
                list-type="picture-card"
                :before-upload="doChangeImage"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="详情图片列表：">
          <span v-if="realseForm.imgList.length">
            <span
              style="position: relative"
              v-for="tmp in realseForm.imgList"
              :key="tmp.imgUrl"
            >
              <i
                @click="doDeleteimage(tmp)"
                class="el-icon-close"
                style="
                  position: absolute;
                  top: -20px;
                  right: 3px;
                  font-size: 16px;
                  color: red;
                  cursor: pointer;
                "
              ></i>
              <img
                style="
                  width: 50px;
                  height: 50px;
                  vertical-align: middle;
                  margin-right: 10px;
                "
                :src="tmp.imgUrl"
                alt=""
              />
            </span>
          </span>
          <el-upload
            style="display: inline-block; vertical-align: middle"
            action=""
            list-type="picture-card"
            :before-upload="doChangeImageMore"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="身份证正面：">
          <el-row>
            <el-col :span="6">
              <div style="position: relative" v-if="realseForm.sfzPositiveImg">
                <img
                  style="
                    width: 50px;
                    height: 50px;
                    vertical-align: middle;
                    margin-right: 10px;
                  "
                  :src="realseForm.sfzPositiveImg"
                  alt=""
                />
                <i
                  @click="realseForm.sfzPositiveImg = ''"
                  style="
                    margin-top: 10px;
                    cursor: pointer;
                    color: red;
                    position: absolute;
                    top: -20px;
                    right: 15px;
                  "
                  class="el-icon-close"
                ></i>
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                style="display: inline-block"
                action=""
                list-type="picture-card"
                :before-upload="doChangeImage1"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="身份证反面：">
          <el-row>
            <el-col :span="6">
              <div style="position: relative" v-if="realseForm.sfzBlackImg">
                <img
                  style="
                    width: 50px;
                    height: 50px;
                    vertical-align: middle;
                    margin-right: 10px;
                  "
                  :src="realseForm.sfzBlackImg"
                  alt=""
                />
                <i
                  @click="realseForm.sfzBlackImg = ''"
                  style="
                    margin-top: 10px;
                    cursor: pointer;
                    color: red;
                    position: absolute;
                    top: -20px;
                    right: 15px;
                  "
                  class="el-icon-close"
                ></i>
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                style="display: inline-block"
                action=""
                list-type="picture-card"
                :before-upload="doChangeImage2"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="房产证图片：">
          <el-row>
            <el-col :span="6">
              <div style="position: relative" v-if="realseForm.deedImg">
                <img
                  style="
                    width: 50px;
                    height: 50px;
                    vertical-align: middle;
                    margin-right: 10px;
                  "
                  :src="realseForm.deedImg"
                  alt=""
                />
                <i
                  @click="realseForm.deedImg = ''"
                  style="
                    margin-top: 10px;
                    cursor: pointer;
                    color: red;
                    position: absolute;
                    top: -20px;
                    right: 15px;
                  "
                  class="el-icon-close"
                ></i>
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                style="display: inline-block"
                action=""
                list-type="picture-card"
                :before-upload="doChangeImage3"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="说明：">
          <el-input
            type="textarea"
            v-model="realseForm.descs"
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="release-btn">
          <el-button
            :disabled="isRealseing"
            @click="doReleaseHouse"
            size="small"
            type="primary"
            >发布房源</el-button
          >
          <el-button v-if="isEdit" @click="doCancel" size="small" type="second"
            >取消</el-button
          >
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: "release-house",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    form: {
      handler(val) {
        console.log(val);
        if (val && val.houseType) {
          this.realseForm.houseType = val.houseType;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      // 发布form
      isEdit: false,
      isRealseing: false,
      sellPrice: "",
      realseForm: {
        nickName: "",
        area: "南京市",
        buildingDate: "",
        checkInDate: "",
        city: "玄武区",
        community: "",
        coverImg: "",
        descs: "",
        houseId: "",
        houseType: 1,
        imgList: [],
        sfzPositiveImg: "",
        sfzBlackImg: "",
        deedImg: "",
        pidPhone: "",
        addressDescs: "",
        isRecommend: null,
        info: {
          architectureType: "",
          decoration: "",
          elevatorFlag: 1,
          proportion: "",
          stayFloor: null,
          totalArea: null,
          totalFloor: null,
          towards: "",
          unitType: "",
        },
        payType: "",
        phone: "",
        province: "江苏省",
        provinceCode: "32,3201,320102",
        rentArea: null,
        rentType: "",
        sellPrice: null,
        title: "",
      },
      rules: {
        area: [{ required: true, message: "省市区域必填！" }],
        title: [{ required: true, message: "标题必填！" }],
        community: [{ required: true, message: "小区必填！" }],
        coverImg: [{ required: true, message: "封面图片必传！" }],
        nickName: [{ required: true, message: "房东姓名必填！" }],
        addressDescs: [{ required: true, message: "详细地址必填！" }],
        "info.architectureType": [
          { required: true, message: "建筑类型必填！" },
        ],
        "info.proportion": [{ required: true, message: "梯户比例必填！" }],
        "info.stayFloor": [{ required: true, message: "所在楼层必填！" }],
        "info.totalArea": [{ required: true, message: "建筑面积必填！" }],
        "info.totalFloor": [{ required: true, message: "总楼层必填！" }],
        "info.towards": [{ required: true, message: "朝向必填！" }],
        "info.unitType": [{ required: true, message: "户型必填！" }],
        "info.elevatorFlag": [{ required: true, message: "有无电梯必填！" }],
        buildingDate: [{ required: true, message: "建成时间必填！" }],
        checkInDate: [{ required: true, message: "入住时间必填！" }],
        phone: [
          { required: true, message: "手机号码必填！" },
          { min: 11, message: "手机号码格式不正确！" },
        ],
        payType: [{ required: true, message: "付款方式必填！" }],
        rentArea: [{ required: true, message: "房屋出租面积必填！" }],
        rentType: [{ required: true, message: "出租方式必填！" }],
        sellPrice: [{ required: true, message: "出租价格必填！" }],
        "info.decoration": [{ required: true, message: "装修情况必填！" }],
      },
      provinceCode: ["32", "3201", "320102"],
      areaInfo: [],
      ti: "",
      hu: "",
      nickNameSex: "先生",
    };
  },

  mounted() {
    this.doFindAreaInfo();
  },

  methods: {
    doDeleteimage(item) {
      this.realseForm.imgList = this.realseForm.imgList.filter(
        (tmp) => tmp.imgUrl !== item.imgUrl
      );
    },
    doCancel() {
      this.isEdit = false;
      this.$emit("cancel");
    },
    doChangeTihu(val, type) {
      if (type === "ti") {
        this.realseForm.info.proportion =
          val + "梯" + (this.hu ? this.hu + "户" : "");
      }
      if (type === "hu") {
        this.realseForm.info.proportion =
          (this.ti ? this.ti + "梯" : "") + val + "户";
      }
    },
    doFindAreaInfo() {
      this.$request.get("/api/house/getAreaList").then((res) => {
        if (res.code === 200) {
          this.areaInfo = res.data;
        } else {
          this.areaInfo = [];
        }
      });
    },
    doReleaseHouse() {
      this.isRealseing = true;
      let data = Object.assign({}, this.realseForm, {
        nickName: (this.realseForm.nickName || "") + this.nickNameSex,
        sellPrice:
          this.realseForm.houseType === 1
            ? this.realseForm.sellPrice * 10000
            : this.realseForm.sellPrice,
      });
      if (this.isEdit) {
        if (this.sellPrice == Number(this.realseForm.sellPrice)) {
          data.sellPriceUpdate = 0;
        } else {
          data.sellPriceUpdate = 1;
        }

        this.$emit("edit", data);

        return;
      }
      this.$refs.realseForm.validate((valid) => {
        if (valid) {
          this.$request.post("/api/house/addHouseInfo", data).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.msg);
              this.$emit("success");
            } else {
              this.$message.warning(res.msg);
            }
            this.isRealseing = false;
          });
        } else {
          this.isRealseing = false;
        }
      });
    },
    doChangeImageMore(file) {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("addInfo", "house");
      this.$request.post("/api/upload/batchUpdateImg", formData).then((res) => {
        if (res.code === 200) {
          this.realseForm.imgList.push({
            imgUrl: res.data.url,
          });
          this.$message.success(res.msg);
        }
      });
      return false;
    },
    doChangeImage(file) {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("addInfo", "house");
      this.$request.post("/api/upload/batchUpdateImg", formData).then((res) => {
        if (res.code === 200) {
          this.realseForm.coverImg = res.data.url;
          this.$message.success(res.msg);
        }
      });
      return false;
    },
    doChangeImage1(file) {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("addInfo", "house");
      this.$request.post("/api/upload/batchUpdateImg", formData).then((res) => {
        if (res.code === 200) {
          this.realseForm.sfzPositiveImg = res.data.url;
          this.$message.success(res.msg);
        }
      });
      return false;
    },
    doChangeImage2(file) {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("addInfo", "house");
      this.$request.post("/api/upload/batchUpdateImg", formData).then((res) => {
        if (res.code === 200) {
          this.realseForm.sfzBlackImg = res.data.url;
          this.$message.success(res.msg);
        }
      });
      return false;
    },
    doChangeImage3(file) {
      const formData = new FormData();
      formData.append("files", file);
      formData.append("addInfo", "house");
      this.$request.post("/api/upload/batchUpdateImg", formData).then((res) => {
        if (res.code === 200) {
          this.realseForm.deedImg = res.data.url;
          this.$message.success(res.msg);
        }
      });
      return false;
    },
    doChangeDate(val) {
      if (val.length === 3) {
        const p = this.areaInfo.find((n) => n.code === val[0]);
        this.realseForm.province = p.name;
        if (p) {
          const a = p.children.find((n) => n.code === val[1]);
          this.realseForm.area = a.name;
          if (a) {
            const c = a.children.find((n) => n.code === val[2]);
            this.realseForm.city = c.name;
          }
        }
        this.realseForm.provinceCode = val.join(",");
      }
    },
  },
};
</script>
<style lang='scss'>
.el-upload--picture-card,
.el-textarea__inner {
  border-radius: 0;
}
</style>
